import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Container from "../../components/Layout/Container";
import { Title, Button, Section, Box, Text } from "../../components/Core";
import data from "../../../site/content/index.json";


const SectionStyled = styled(Section)`
  position: relative;
  min-height: 70vh;
  background: rgb(2,29,45);
  background: linear-gradient(90deg, rgba(2,29,45,1) 0%, rgba(10,118,182,1) 65%);
`;

const ImageContainer = styled.div`
  display: none;
  padding-top: 40px;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col md="6" sm="9" className="order-lg-2">
              <ImageContainer className="text-center text-lg-right position-relative">
                <img
                  src={data.hero.image.image}
                  alt={data.hero.image.alt}
                  style={{
                    width: '120%',
                    marginLeft: '-20%'
                  }}
                />
              </ImageContainer>
            </Col>
            <Col
              sm="12"
              md="6"
              className="order-lg-1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: '60vh'
              }}
            >
              {" "}
              <Title variant="hero" textColor={'#FFF'}>
                <span>{data.hero.title["title-1"]}</span>
                <br />
                <span>{data.hero.title["title-2"]}</span>
              </Title>
              <div style={{ maxWidth: 600 }}>
                <Text mb={4} fontSize={22} textColor={'#FFF'}>
                  {data.hero.text}
                </Text>
              </div>
              <div className="d-flex align-items-start flex-wrap">
                {data.hero.buttons.map(
                  ({ to, text, color, variant }, index) => (
                    <Link key={index} to={to}>
                      <Button mt={4} variant={variant} color={color} mr={3}>
                        {text}
                      </Button>
                    </Link>
                  )
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
