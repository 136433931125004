import React from "react";

import { Title, Text } from "../../components/Core";
import Container from "../../components/Layout/Container";

interface Props {
  title: string;
  text: string;
}

const SectionTitle = ({ title, text }: Props) => (
  <Container mt={90} mb={60}>
    <Title textAlign="center" marginBottom="10px !important">
      {title}
    </Title>
    <Text textAlign="center">
      {text}
    </Text>
  </Container>
);

export default SectionTitle;
