import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import Container from "../../components/Layout/Container";
import { Section, Box, Text } from "../../components/Core";
import data from "../../../site/content/index.json";

const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #0d333f),
    color-stop(85%, rgba(13, 51, 63, 0))
  );
  background: linear-gradient(90deg, #0d333f 25%, rgba(13, 51, 63, 0) 85%);
  mix-blend-mode: multiply;
`;

const LinkText = styled.p`
  margin: 0;
  color: #fff;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;

const Card = styled(Box)`
  cursor: pointer;
  border: 1px solid #eae9f2;
  /* height: 100%; */
  position: relative;
  overflow: hidden;
  height: 230px;
  &:hover {
    box-shadow: ${({ theme }) => `0 10px 20px ${theme.colors.shadow}`};
  }
`;

const CardContent = styled.div`
  z-index: 2;
  position: relative;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  height: 230px;
`;

const ImageContainer = styled.div`
  height: 230px;
  width: 100%;
  background-size: cover;
  background-position: top right;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: transform 0.5s;
`;

interface FeatureCardProps {
  color?: string;
  title?: string;
  iconName?: string;
  image?: string;
  points?: any;
  to?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color = "primary",
  iconName,
  title,
  image,
  points,
  to,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Link to={to}>
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        bg="light"
        py="0px"
        px="0px"
        borderRadius={10}
        {...rest}
      >
        <ImageContainer
          style={{
            backgroundImage: `url(${image})`,
            transform: `scale(${hover ? 1.05 : 1})`,
          }}
        />
        <CardOverlay />
        <CardContent>
          <Text
            color="white"
            as="h3"
            fontSize="28px"
            lineHeight="35px"
            fontWeight={500}
            letterSpacing={-0.75}
            my={1}
            style={{ maxWidth: '60%', flex: 1 }}
          >
            {title}
          </Text>
          <Link>
            <LinkText>Find out how we can help you</LinkText>
          </Link>
        </CardContent>
      </Card>
    </Link>
  );
};

const Feature = ({
  bg = "#f7f7fb",
  noTitle = false,
}: {
  bg?: string;
  noTitle?: boolean;
}) => (
  <>
    {/* <!-- Feature section --> */}
    <Section bg={bg} className="position-relative">
      <Container>
        <Row>
          {data.features.features.map(({ title, image, points, to }, index) => (
            <Col lg="4" md="6">
              <FeatureCard
                key={index}
                image={image}
                points={points}
                title={title}
                to={to}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
