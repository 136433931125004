import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../sections/landing1/Hero";
import Feature from "../sections/landing1/CustomerFeatures";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Content3 from "../sections/landing1/Content3";
import Content4 from "../sections/landing1/Content4";

import Testimonial from "../sections/landing1/Testimonial";

import PageWrapper from "../components/PageWrapper";
import SectionTitle from "../components/Content/SectionTitle";
import TextCTA from "../components/Content/TextCTA";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>
            ShopSafe - The  crime
            reduction ecosystem.
          </title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero />
        {/* <Clients /> */}
        <Feature />
        <SectionTitle
          text="A set of purpose built modern tools that provide your business with everything it needs to reduce loss and ASB."
          title="Your Crime Fighting Toolbox"
        />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <TextCTA
          buttonText="Book a demo"
          text="Book an online demo with one of our product experts to see see our solutions in action."
          title="Like what you see?"
        />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
